import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import HomepageSlides from "../components/homepageSlides";
import Layout from "../components/layout";
import Audits from "../components/audits";
import Buttons from "../components/buttons";
import Arrow from "../components/arrow";
import Capability from "../components/capability";
import capabilityimage1 from "../images/capability-digital-strategy-2.png";
import capabilityimage2 from "../images/capability-digital-media-2.png";
import capabilityimage3 from "../images/capability-organic-search-2.png";
import capabilityimage4 from "../images/capability-social-2.png";
import capabilityimage5 from "../images/capability-ux-cx-design-2.png";
import capabilityimage6 from "../images/capability-web-and-app-design-3.png";
import capabilityimage7 from "../images/capability-creative-content-and-production-2.png";
import capabilityimage8 from "../images/capability-conversion-rate-optimisation-2.png";
import capabilityimage9 from "../images/capability-crm-and-loyalty-2.png";
import "../scss/index.scss";

import "../scss/capabilities.scss";


class IndexPage extends React.Component {
    render() {
        const metadescription = "Next&Co are an independent digital and traditional performance marketing agency, visit our site or call 1300 191 950";
        const metatitle = "Independent Performance Digital Marketing Agency - Next&Co";
        const metaurl = typeof window !== 'undefined' ? window.location.href : '';

        return (
            <>
              <Helmet>
                <title>{metatitle}</title>
                <meta name="description" content={metadescription}/>
                <meta name="title" content={metatitle} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={metaurl} />
                <meta property="og:title" content={metatitle} />
                <meta property="og:description" content={metadescription} />
                <meta property="og:image" content="facebook.png" />
                <meta property="twitter:url" content={metaurl} />
                <meta property="twitter:title" content={metatitle} />
                <meta property="twitter:description" content={metadescription} />
                <meta property="twitter:image" content="twitter.png" />
              </Helmet>
              <Arrow/>
              <Layout>
                <HomepageSlides/>
                <section id="capabilities" className="row-capabilities">
                  <div className="outer-container">
                    <div className="container-max-width">
                      <div className="inner-container">
                        <div className="row-one">
                          <h2>Our capabilities</h2>
                        </div>
                        <div className="outer-container">
                          <div className="container-max-width">
                            <div className="col">
                              <Capability
                                capabilityimage = {capabilityimage1}
                                capabilitytitle = "Digital Strategy"
                                capabilitylink = "/capabilities/digital-strategy/"
                              />
                              <Capability
                                capabilityimage = {capabilityimage2}
                                capabilitytitle = "Digital Media"
                                capabilitylink = "/capabilities/digital-media/"
                              />
                              <Capability
                                capabilityimage = {capabilityimage3}
                                capabilitytitle = "Organic Search"
                                capabilitylink = "/capabilities/organic-search/"
                              />
                              <Capability
                                capabilityimage = {capabilityimage4}
                                capabilitytitle = "Social"
                                capabilitylink = "capabilities/social/"
                              />
                              <Capability
                                capabilityimage = {capabilityimage5}
                                capabilitytitle = "UX/CX Design"
                                capabilitylink = "/capabilities/ux-cx-design/"
                              />
                              <Capability
                                capabilityimage = {capabilityimage6}
                                capabilitytitle = "Web & App Design"
                                capabilitylink = "/capabilities/web-and-app-design/"
                              />
                              <Capability
                                capabilityimage = {capabilityimage7}
                                capabilitytitle = "Creative & Content Production"
                                capabilitylink = "/capabilities/creative-content-and-production/"
                              />
                              <Capability
                                capabilityimage = {capabilityimage8}
                                capabilitytitle = "Conversion Rate Optimisation"
                                capabilitylink = "/capabilities/conversion-rate-optimisation/"
                              />
                              <Capability
                                capabilityimage = {capabilityimage9}
                                capabilitytitle = "CRM & Loyalty"
                                capabilitylink = "/capabilities/crm-and-loyalty/"
                              />
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                </section>


                <Audits />
                <Buttons
                  linktitle1="About us"
                  link1="/about"
                  linktitle2="Get in touch"
                />
              </Layout>
            </>
        )
    }
}

export default IndexPage

export const query = graphql`
  query {
    file(relativePath: { eq: "hero2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
