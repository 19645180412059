import React from 'react';
import { Link } from "gatsby";
import { Controller, Scene } from 'react-scrollmagic';

import "../scss/homeSlider.scss"

class HomepageSlides extends React.Component {
    render() {
        return (
            <div className={`home-section-slides`}>
              <Controller>

                {/* ICARE */}
                <Scene pin={true} triggerHook={0} classToggle="is-active">
		  <div className="home-section home-section-icare">
		    <div className="background"/>
		    <div className="number-wrapper">
		      <div className="content-wrapper-background-container">
			<div className="wrapper">
			  01 <span className="line"></span>
                          Design
			</div>
		      </div>
		    </div>
		    <div className="content-wrapper">
		      <div className="content-wrapper-background-container">
			<h2 className="content-wrapper-heading">icare</h2>
		      </div>
		      <div className="content-wrapper-background-container content">
			<p className="content-wrapper-content">
                          icare is an Australian, family-run business producing quality recycled toilet paper and tissues that consistently tests comparatively in comfort and quality against the major, non-recycled brands. 
                        </p>
		      </div>
		      <div className="button-container"> 
			<Link className="btn btn-invert" to="/case-studies/icare"> 
			  View case study 
			</Link> 
		      </div> 
		    </div>
		  </div>
                </Scene>
                
                {/* PEARSON */}
                <Scene pin={true} triggerHook={0} classToggle="is-active">
		  <div className="home-section home-section-pearson">
		    <div className="background"/>
		    <div className="number-wrapper">
		      <div className="content-wrapper-background-container">
			<div className="wrapper">
			  02 <span className="line"></span>
                          Experience
			</div>
		      </div>
		    </div>
		    <div className="content-wrapper">
		      <div className="content-wrapper-background-container">
			<h2 className="content-wrapper-heading">
                          Pearson
                        </h2>
		      </div>
		      <div className="content-wrapper-background-container content">
			<p className="content-wrapper-content">
                          Pearson is the world's leading learning company providing education solutions to educators and students across all ages, interests and specialisations. In Australia and worldwide, the Pearson brand is well recognised and respected. 
                        </p>
		      </div>
		      <div className="button-container"> 
			<Link className="btn btn-invert" to="/case-studies/pearson"> 
			  View case study 
			</Link> 
		      </div> 
		    </div>
		  </div>
                </Scene>

                {/* MAVEN DENTAL */}
                <Scene pin={true} triggerHook={0} classToggle="is-active">
		  <div className="home-section home-section-maven-dental">
		    <div className="background"/>
		    <div className="number-wrapper">
		      <div className="content-wrapper-background-container">
			<div className="wrapper">
			  03 <span className="line"></span>
                          Experience
			</div>
		      </div>
		    </div>
		    <div className="content-wrapper">
		      <div className="content-wrapper-background-container">
			<h2 className="content-wrapper-heading">
                          Maven Dental
                        </h2>
		      </div>
		      <div className="content-wrapper-background-container content">
			<p className="content-wrapper-content">
                          Maven Dental is Australia's largest non-insurance based dental support organisation. With more than 100 locations across the country and the backing of their parent company, Abano Healthcare Group, Maven Dental's brand already had a strong market presence.
                        </p>
		      </div>
		      <div className="button-container"> 
			<Link className="btn btn-invert" to="/case-studies/maven-dental"> 
			  View case study 
			</Link> 
		      </div> 
		    </div>
		  </div>
                </Scene>

                {/* SESAME STREET */}
                <Scene pin={true} triggerHook={0} classToggle="is-active">
		  <div className="home-section home-section-1">
		    <div className="background"/>
		    <div className="number-wrapper">
		      <div className="content-wrapper-background-container">
			<div className="wrapper">
			  04 <span className="line"></span>Digital Media Buying
			</div>
		      </div>
		    </div>
		    <div className="content-wrapper">
		      <div className="content-wrapper-background-container">
			<h2 className="content-wrapper-heading">Sesame Street</h2>
		      </div>
		      <div className="content-wrapper-background-container content">
			<p className="content-wrapper-content">With an abundance of iconic characters instantly recognisable across multiple generations.</p>
		      </div>
		      <div className="button-container"> 
			<Link className="btn btn-invert" to="/case-studies/sesame-street">
			  View case study 
			</Link> 
		      </div> 
		    </div>
		  </div>
                </Scene>

                {/* {/\* STRATTON FINANCE *\/} */}
                {/* <Scene pin={true} triggerHook={0} classToggle="is-active"> */}
		{/*   <div className="home-section home-section-2"> */}
		{/*     <div className="background"/> */}
		{/*     <div className="number-wrapper"> */}
		{/*       <div className="content-wrapper-background-container"> */}
		{/* 	<div className="wrapper"> */}
		{/* 	  05 <span className="line"></span>Search Engine Optimisation */}
		{/* 	</div> */}
		{/*       </div> */}
		{/*     </div> */}
		{/*     <div className="content-wrapper"> */}
		{/*       <div className="content-wrapper-background-container"> */}
		{/* 	<h2 className="content-wrapper-heading">Stratton Finance</h2> */}
		{/*       </div> */}
		{/*       <div className="content-wrapper-background-container content"> */}
		{/* 	<p className="content-wrapper-content">Stratton Finance are the leading car finance website in Australia, assisting Australians with buying their new car.</p> */}
		{/*       </div> */}
		{/*       <div className="button-container">  */}
		{/* 	<Link className="btn btn-invert" to="/case-studies/stratton-finance">  */}
		{/* 	  View case study  */}
		{/* 	</Link>  */}
		{/*       </div>  */}
		{/*     </div> */}
		{/*   </div> */}
                {/* </Scene>                 */}

                {/* {/\* GET WINES *\/} */}
                {/* <Scene pin={true} triggerHook={0} classToggle="is-active"> */}
	        {/*   <div className="home-section home-section-3"> */}
		{/*     <div className="background"/> */}
		{/*     <div className="number-wrapper"> */}
		{/*       <div className="content-wrapper-background-container"> */}
		{/*         <div className="wrapper"> */}
		{/*           06 <span className="line"></span> */}
		{/*           Search Engine Optimisation */}
		{/*         </div> */}
		{/*       </div> */}
		{/*     </div> */}
		{/*     <div className="content-wrapper"> */}
		{/*       <div className="content-wrapper-background-container"> */}
		{/*         <h2 className="content-wrapper-heading">Get Wines Direct</h2> */}
		{/*       </div> */}
		{/*       <div className="content-wrapper-background-container content"> */}
		{/*         <p className="content-wrapper-content"> */}
		{/*           Get Wines Direct is a pioneering online wine retailer delivering its customers some of the best drops from Australia and around the world.  */}
		{/*         </p> */}
		{/*       </div> */}
		{/*       <div className="button-container">  */}
		{/*         <Link className="btn btn-invert" to="/case-studies/get-wines">  */}
		{/*           View case study  */}
		{/*         </Link>  */}
		{/*       </div>  */}
		{/*     </div> */}
	        {/*   </div> */}
                {/* </Scene> */}

                {/* {/\* AUSTRALIAN VITAMINS *\/} */}
                {/* <Scene pin={true} triggerHook={0} classToggle="is-active"> */}
		{/*   <div className="home-section home-section-4"> */}
		{/*     <div className="background"/> */}
		{/*     <div className="number-wrapper"> */}
		{/*       <div className="content-wrapper-background-container"> */}
		{/* 	<div className="wrapper"> */}
		{/* 	  07 <span className="line"></span> */}
		{/* 	  Digital Media */}
		{/* 	</div> */}
		{/*       </div> */}
		{/*     </div> */}
		{/*     <div className="content-wrapper"> */}
		{/*       <div className="content-wrapper-background-container"> */}
		{/* 	<h2 className="content-wrapper-heading">Australian Vitamins</h2> */}
		{/*       </div> */}
		{/*       <div className="content-wrapper-background-container content"> */}
		{/* 	<p className="content-wrapper-content"> */}
		{/* 	  Australian Vitamins is a long-standing premium online health food brand with an extremely loyal customer base and high-quality Australian products which speak for themselves. */}
		{/* 	</p> */}
		{/*       </div> */}
		{/*       <div className="button-container">  */}
		{/* 	<Link className="btn btn-invert" to="/case-studies/australian-vitamins">  */}
		{/* 	  View case study  */}
		{/* 	</Link>  */}
		{/*       </div>  */}
		{/*     </div> */}
		{/*   </div> */}
                {/* </Scene> */}

                {/* {/\* LE BUNS *\/} */}
                {/* <Scene pin={true} triggerHook={0} classToggle="is-active"> */}
		{/*   <div className="home-section home-section-5"> */}
		{/*     <div className="background"/> */}
		{/*     <div className="number-wrapper"> */}
		{/*       <div className="content-wrapper-background-container"> */}
		{/* 	<div className="wrapper"> */}
		{/* 	  08 <span className="line"></span>Web Development */}
		{/* 	</div> */}
		{/*       </div> */}
		{/*     </div> */}
		{/*     <div className="content-wrapper"> */}
		{/*       <div className="content-wrapper-background-container"> */}
		{/* 	<h2 className="content-wrapper-heading">Le Buns</h2> */}
		{/*       </div> */}
		{/*       <div className="content-wrapper-background-container content"> */}
		{/* 	<p className="content-wrapper-content">Le Buns is an up and coming Australian swimwear label that puts sustainability and environmental consciousness at the centre of what they do.</p> */}
		{/*       </div> */}
		{/*       <div className="button-container">  */}
		{/* 	<Link className="btn btn-invert" to="/case-studies/le-buns">  */}
		{/* 	  View case study  */}
		{/* 	</Link>  */}
		{/*       </div>  */}
		{/*     </div> */}
		{/*   </div> */}
                {/* </Scene> */}

              </Controller>
            </div>
        )
    }
}
export default HomepageSlides;

